<template>
  <v-card>
    <v-card-title>Métodos de pagamento</v-card-title>

    <v-data-table
      :headers="headers"
      :items="paymentMethods"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click="$router.push('/metodos-de-pagamento/cadastrar')"
            >Cadastrar +</v-btn
          >
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Editar método de pagamento"
          @click="editPaymentMethod(item.id)"
          small
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Deletar método de pagamento"
          @click="deletePaymentMethod(item.id)"
          small
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    paymentMethods: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async loadPaymentMethods() {
      try {
        this.loading = true;

        let url = `/payment-methods`;

        const response = await this.$axios.get(url);

        this.setResponseData(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setResponseData(data) {
      const cpData = [...data];
      this.paymentMethods = cpData;
    },
    async deletePaymentMethod(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar o método de pagamento com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/payment-methods/${id}`;

        await this.$axios.delete(url);

        this.deletePaymentMethodFromTable(id);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deletePaymentMethodFromTable(id) {
      const index = this.paymentMethods.findIndex((p) => p.id === id);

      this.paymentMethods.splice(index, 1);
    },
    editPaymentMethod(id) {
      this.$router.push(`/metodos-de-pagamento/${id}`);
    },
  },
  computed: {},
  created() {
    this.loadPaymentMethods();
  },
};
</script>

<style></style>
